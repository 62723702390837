import { FC, useContext } from 'react';
import { Typography, Col, Row } from "@icts/web";
const { Text, Title } = Typography;
import * as S from "./styles";
import { color } from "@icts/tokens";
import Icon from "icts-icon";
import { useAtom } from "jotai";
import { companyAtom } from "../../../../store";
import { AuthContext } from '@/Contexts/auth';
import { add, startOfMonth, format } from "date-fns";
import { getIfTodayIsAValidDayToUploadTaxes } from '@/utils/getIfTodayIsAValidDayToUploadTaxes';

interface Props {
  setOpenRegisterInvoiceModalICTS: any;
  setUsingICTSModalForTaxes: (isUsing: boolean) => void;
}

export const PageTitle: FC<Props> = ({ setOpenRegisterInvoiceModalICTS, setUsingICTSModalForTaxes }) => {
  const { user } = useContext(AuthContext);
  const [company] = useAtom(companyAtom);
  const todayIsAValidDayToUploadTaxes = getIfTodayIsAValidDayToUploadTaxes()
  const firstDayFromNextMonth = startOfMonth(add(new Date(), { months: 1 }))
  const userCanAddTax = user?.resource_access?.portal_fornecedores?.roles?.some(role => role === 'master')

  return (
    <S.Container justify="space-between" align="middle">
      <Col>
        <Title style={{ color: color.neutral.light.up }}>Minhas Notas</Title>
        <Text style={{ color: color.neutral.light.up }}>{company.razao_social} {company.cnpj}</Text>
      </Col>

      <Col>
        <Row align="middle" justify='center'>
          {/* {!todayIsAValidDayToUploadTaxes && (
            <S.AlertContainer>
                <S.CustomIcon>
                  <Icon name="alerta" size="md" />
                </S.CustomIcon>
                <div
                  style={{
                    padding: '0.5em',
                    paddingLeft: '0px',
                  }}
                >
                  <Text>
                    Período para lançamento de NF encerrado. Você poderá lançar uma nova NF a partir de {format(firstDayFromNextMonth, 'dd/MM/yyyy')}.
                  </Text>
                </div>
            </S.AlertContainer>
          )} */}

          {import.meta.env.VITE_COMPANY_ENV === 'ICTS' && (
            <>
              {company?.cnpj && userCanAddTax && (
                <S.RegisterInvoiceButton
                  shape="round"
                  style={{ marginRight: 20 }}
                  disabled={!todayIsAValidDayToUploadTaxes}
                  onClick={() => {
                    setUsingICTSModalForTaxes(true)
                    setOpenRegisterInvoiceModalICTS(true)
                  }}
                >
                  + Cadastrar Imposto
                </S.RegisterInvoiceButton>
              )}

              {company?.cnpj && (
                <S.RegisterInvoiceButton
                  shape="round"
                  // disabled={!todayIsAValidDayToUploadTaxes}
                  onClick={() => setOpenRegisterInvoiceModalICTS(true)}
                >
                  + Cadastrar Nota
                </S.RegisterInvoiceButton>
              )}
            </>
          )}
        </Row>
      </Col>
    </S.Container>
  );
};
